import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Säsongsavslutningen av Trevlig Mjukvara är fullspäckad med nyheter kring KDE Plasma, ARM, Vue och Pine64. Mozilla hamnar i återblickslinsen och Alex och Seb är experter på att sväva iväg.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Telemetri i Plasma: `}<a parentName="li" {...{
          "href": "http://blog.davidedmundson.co.uk/blog/what-we-can-learn-from-plasma-telemetry/"
        }}>{`http://blog.davidedmundson.co.uk/blog/what-we-can-learn-from-plasma-telemetry/`}</a></li>
      <li parentName="ul">{`Tjuvkoll på Plasma 5.20: `}<a parentName="li" {...{
          "href": "https://www.omgubuntu.co.uk/2020/09/kde-plasma-5-20-release-features"
        }}>{`https://www.omgubuntu.co.uk/2020/09/kde-plasma-5-20-release-features`}</a></li>
      <li parentName="ul">{`"Save Arm": `}<a parentName="li" {...{
          "href": "https://savearm.co.uk/"
        }}>{`https://savearm.co.uk/`}</a></li>
      <li parentName="ul">{`Vue 3.0: `}<a parentName="li" {...{
          "href": "https://github.com/vuejs/vue-next/releases/tag/v3.0.0"
        }}>{`https://github.com/vuejs/vue-next/releases/tag/v3.0.0`}</a></li>
      <li parentName="ul">{`Pine64: `}<a parentName="li" {...{
          "href": "https://www.pine64.org/2020/09/15/september-update-let-it-sink-in/"
        }}>{`https://www.pine64.org/2020/09/15/september-update-let-it-sink-in/`}</a></li>
    </ul>
    <h2>{`Återblick`}</h2>
    <ul>
      <li parentName="ul">{`Firefox Send & Notes dör: `}<a parentName="li" {...{
          "href": "https://blog.mozilla.org/blog/2020/09/17/update-on-firefox-send-and-firefox-notes/"
        }}>{`https://blog.mozilla.org/blog/2020/09/17/update-on-firefox-send-and-firefox-notes/`}</a></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Säsongsbrejk!`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      